<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ alias: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ alias: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ alias: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Create new file</h1>
                <!-- <p class="text-caption text-left mt-8">This will create an independent form for storing your data.</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Create new file</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit="create" onSubmit="return false;" @keyup.enter.native="create">
                            <p>
                                Files are used to import and export data. They are NOT used directly to send any emails.
                            </p>

                            <!-- <v-select outlined dense :items="newFileTypeChoices" v-model="newFileType" color="teal darken-2" label="Type" required hint="The kind of data to which this file will be applied">
                            </v-select> -->

                            <v-text-field
                                ref="fileLabelInput"
                                v-model="newFileLabel"
                                label="Label"
                                :rules="newFileLabelRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>

                            <v-text-field
                                ref="fileCommentInput"
                                v-model="newFileComment"
                                label="Comment"
                                hint="This is just for you to remember how you are using the file"
                                :rules="newFileCommentRules"
                                validate-on-blur
                                color="teal darken-2"
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>

                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="create" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        typeList: [],

        error: null,
        website: null,
        // create form
        createFileForm: null,
        // newFormAlias: null,
        // newFormAliasRules: [
        //     (v) => !!v || 'Display alias is required',
        //     (v) => !v || isValidFormAlias(compact(v)) || 'Form alias is required',
        // ],
        newFileLabel: null,
        newFileLabelRules: [
            (v) => !!v || 'Label is required',
        ],
        // newFileType: null,
        // newFileTypeChoices: [], // will be loaded from server
        newFileComment: null,
        newFileCommentRules: [
            // (v) => !!v || 'comment is required',
        ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            return typeof this.newFileLabel === 'string' && this.newFileLabel.trim().length > 0;
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        // async loadWebsite(id) {
        //     try {
        //         this.$store.commit('loading', { loadWebsite: true });
        //         const response = await this.$client.account(this.$route.params.accountId).website.get(id);
        //         console.log(`loadWebsite: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.website = response;
        //             // if the form is for a website, suggest an appropriate form label (user can still edit this)
        //             if (this.newFileLabel === null || this.newFileLabel.length === 0) {
        //                 this.newFileLabel = `Website content for ${this.website.label}`;
        //             }
        //         }
        //     } catch (err) {
        //         console.error('failed to load website', err);
        //         this.website = null;
        //     } finally {
        //         this.$store.commit('loading', { loadWebsite: false });
        //     }
        // },
        create() {
            if (!this.isFormComplete) {
                return;
            }
            this.createFile();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createFile() {
            try {
                this.error = false;
                this.$store.commit('loading', { createFile: true });
                console.log('createFile');
                const request = {
                    label: this.newFileLabel,
                    // type: this.newFileType,
                    comment: this.newFileComment,
                };
                const response = await this.$client.account(this.$route.params.accountId).file.create(request);
                console.log('createFile response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-file', params: { accountId: this.$route.params.accountId, fileId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create file' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create file' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create file', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create file' });
            } finally {
                this.$store.commit('loading', { createFile: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        // this.loadTypeList();
    },
};
</script>
